import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AssetV2 } from 'types/asset.type';
import Icon from '@mui/material/Icon';
import MenuItem from 'components/ui-components-v2/MenuItem';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import Translation from 'components/data/Translation';

import './styles/delete-permanently.scss';

interface Props {
    asset: AssetV2<unknown, unknown>;
    onSetAssetToRemove: (asset: AssetV2<unknown, unknown>, permanent: boolean) => void;
    onClose: () => void;
}

const AssetManagementAssetMenuItemsDeletePermanently = ({ asset, onSetAssetToRemove, onClose }: Props) => {
    const { languageNameSpace, userHasRight } = useAssetManagementConfigContext();

    if (!userHasRight('management')) return null;

    return (
        <MenuItem
            onClick={(e) => {
                e.stopPropagation();
                onSetAssetToRemove(asset, true);
                onClose();
            }}>
            <ListItemIcon>
                <Icon color="error">delete_forever</Icon>
            </ListItemIcon>
            <ListItemText className="asset-management-asset-menu-items-delete-permanently__remove">
                {Translation.get('dialogs.removeAssetPermanent.title', languageNameSpace)}
            </ListItemText>
        </MenuItem>
    );
};

export default AssetManagementAssetMenuItemsDeletePermanently;
