import React from 'react';
import { Collection } from 'types/collection.type';

interface Props {
    hovered: boolean;
    collection: Collection;
    children?: React.ReactElement;
}

import '../styles/display-overlay.scss';
import useAMCollectionSelection from 'components/asset-management/hooks/useAMCollectionSelection';

const AssetManagementCollectionTileDisplayOverlay = ({ hovered, collection, children }: Props) => {
    const { isSelected } = useAMCollectionSelection(collection.id);

    if (!hovered && !isSelected) return null;

    return <div className="asset-management-collection-tile-display-overlay">{children && children}</div>;
};

export default AssetManagementCollectionTileDisplayOverlay;
