import React, { useState } from 'react';
import { Collection } from 'types/collection.type';
import { AssetManagementCollectionTileDisplay } from 'components/asset-management/components/collection-tile';
import { SimpleCollection } from '../types/simple-collection.type';

interface Props {
    collection: Collection;
    onSelectCollection: (simpleCollection: SimpleCollection) => void;
}

const AssetMangementSelectorCollectionTile = ({ collection, onSelectCollection }: Props) => {
    const [hovered, setHovered] = useState(false);

    const onClick = () => {
        onSelectCollection({ id: collection.id, title: collection.title });
    };

    return <AssetManagementCollectionTileDisplay collection={collection} hovered={hovered} onSetHovered={setHovered} onHandleClick={onClick} />;
};

export default AssetMangementSelectorCollectionTile;
