import React, { ReactElement, useEffect, useState } from 'react';
import { set } from 'lodash';
import { Collection } from 'types/collection.type';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import { Tooltip } from '@mui/material';
import { FilterSetup } from 'types/filter.type';
import { AssetSubType, AssetV2 } from 'types/asset.type';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import Translation from 'components/data/Translation';
import {
    getAssetListStateFromResponse,
    getChangedFilter,
    getCurrentFilterSetup,
    getListParams,
    transformAssetParamsForRootKeys
} from 'components/asset-management/utilities';
import { AssetListPayload, FetchPayload, MetricsUsageUseCase, SortDirection, SortField } from 'components/asset-management/types/asset-management.service.type';
import { AssetListState } from 'components/asset-management/types/asset-management-store.type';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import AssetManagementSelectorFilterBar from './filter-bar';
import AssetManagementSelectorCollectionsView from './collections-view';
import { SimpleCollection } from '../types/simple-collection.type';
import AssetManagementSelectorAssetsView from './assets-view';
import AssetManagementSelectorTabs from './tabs';

import '../styles/collection.scss';

interface Props {
    dataFilters?: {
        [key: string]: string | string[] | boolean;
    };
    subType?: AssetSubType[];
    categories: string[] | null;
    hideFilters: string[];
    objectName?: MetricsUsageUseCase | null;
    objectId?: string | number | null;
    openCollection: SimpleCollection;
    assetTile: ReactElement;
    onSelect: (item: AssetV2<unknown, unknown>) => void;
    onSelectCollection: (collection: SimpleCollection) => void;
    onSelectCollectionById: (collectionId?: string) => void;
}

const ASSET_STATUS = 'available';

const AssetManagementSelectorCollection = ({
    categories,
    hideFilters,
    dataFilters,
    subType,
    objectName,
    objectId,
    openCollection,
    assetTile,
    onSelect,
    onSelectCollection,
    onSelectCollectionById
}: Props) => {
    const { assetFilterSetup: filterSetup, type } = useAssetManagementConfigContext();

    const [listState, setListState] = useState<AssetListState>({
        assetsList: null,
        assetsNextPageToken: 0,
        assetsCounts: {},
        assetFilters: {},
        assetSortField: undefined,
        assetSortDirection: undefined,
        totalAssetsCount: 0,
        filteredAssetsCount: 0
    });

    const [currentFilterSetup, setCurrentFilterSetup] = useState<FilterSetup>(filterSetup);
    const [collection, setCollection] = useState<Collection | null>(null);
    const [subCollectionsList, setSubCollectionsList] = useState<Collection[] | null>(null);
    const [assetsLoading, setAssetsLoading] = useState(true);

    if (!openCollection) return null;

    useEffect(() => {
        if (openCollection?.id) {
            AssetManagementService.getCollection(openCollection.id).then((response) => {
                setCollection(response);
            });
            getContent(true);
        }
    }, [openCollection?.id]);

    const getContent = (refresh: boolean, payload?: FetchPayload) => {
        const shouldRefresh = Boolean(refresh || payload);
        if (shouldRefresh) setAssetsLoading(true);

        // Do not wait for the API to set the choosen filters.
        setListState((prevListState: AssetListState) => {
            return { ...prevListState, assetFilters: payload?.filters || {} };
        });

        const params: AssetListPayload = getListParams(
            type,
            {
                stateList: listState.assetsList,
                stateNextPageToken: listState.assetsNextPageToken,
                stateFilters: listState.assetFilters,
                stateSortField: listState.assetSortField,
                stateSortDirection: listState.assetSortDirection
            },
            ASSET_STATUS,
            shouldRefresh,
            payload
        );
        if (subType) set(params, 'filters.subType', subType);
        if (dataFilters && Object.keys(dataFilters).length) set(params, 'dataFilters', dataFilters);
        if (objectName && objectId) {
            set(params, 'objectName', objectName);
            set(params, 'objectId', objectId);
        }
        const transformedAssetsParams = transformAssetParamsForRootKeys(params);
        AssetManagementService.getCollectionItems(openCollection.id, transformedAssetsParams, true).then((response) => {
            setListState((prevListState: AssetListState) => {
                if (subType && params.filters?.subType) delete params.filters.subType;
                const newListState = getAssetListStateFromResponse(response, prevListState.assetsList || [], shouldRefresh, params);
                return { ...prevListState, ...newListState };
            });
            if (response.collections) setSubCollectionsList(response.collections);
            const hideFilters: string[] = [];
            if (subType) {
                hideFilters.push('subType');
            }
            setCurrentFilterSetup(getCurrentFilterSetup(filterSetup, response.counts, params.filters || {}, hideFilters));
            setAssetsLoading(false);
        });
    };

    const changeFilter = (key: string, value: string | string[]) => {
        const filters = getChangedFilter(listState.assetFilters, key, value);
        getContent(true, { filters });
    };

    const clearFilter = () => {
        getContent(true, { filters: {} });
    };
    const sort = (sortField: SortField, sortDirection: SortDirection) => {
        getContent(true, { sorting: { sortField, sortDirection } });
    };

    return (
        <div className="asset-management-selector-collection">
            <div className="asset-management-selector-collection__navigation">
                <Tooltip title={Translation.get('labels.back', 'common')}>
                    <IconButton onClick={() => onSelectCollectionById(collection?.parentId)} size="small">
                        <Icon>arrow_back</Icon>
                    </IconButton>
                </Tooltip>
                <div className="asset-management-selector-collection__title">{collection?.title || openCollection.title}</div>
                <div className="asset-management-selector-collection__subtypes">
                    {Translation.get('filters.count', 'ui-base', { count: listState.filteredAssetsCount, total: listState.totalAssetsCount })}
                </div>
            </div>
            <AssetManagementSelectorFilterBar
                filterSetup={currentFilterSetup}
                filters={listState.assetFilters}
                hideFilters={hideFilters}
                sortField={listState.assetSortField}
                sortDirection={listState.assetSortDirection}
                onChangeFilter={changeFilter}
                onClearFilter={clearFilter}
                onSort={sort}
            />
            <AssetManagementSelectorCollectionsView
                collectionsList={subCollectionsList}
                className="asset-management-selector-collection__subcollections"
                onSelectCollection={onSelectCollection}
            />
            {categories && (
                <AssetManagementSelectorTabs
                    tabs={[
                        { label: Translation.get('labels.all', 'common'), value: '_all' },
                        ...categories.map((category) => ({ label: category, value: category }))
                    ]}
                    activeTab={listState.assetFilters.categories?.[0] || '_all'}
                    className="asset-management-selector-collection__categories"
                    onChange={(value) => changeFilter('categories', value === '_all' ? [] : [value])}
                />
            )}
            <AssetManagementSelectorAssetsView
                assetsList={listState.assetsList}
                hasMore={Boolean(listState.assetsNextPageToken)}
                loading={assetsLoading}
                assetTile={assetTile}
                onSelect={onSelect}
                onGetContent={getContent}
            />
        </div>
    );
};

export default AssetManagementSelectorCollection;
