import React from 'react';
import classNames from 'classnames';
import { Collection } from 'types/collection.type';
import GridWrapper from 'components/ui-components/GridWrapper';
import AssetMangementSelectorCollectionTile from './collection-tile';
import { SimpleCollection } from '../types/simple-collection.type';

import '../styles/collections-view.scss';

interface Props {
    collectionsList: Collection[] | null;
    className?: string;
    onSelectCollection: (collection: SimpleCollection) => void;
}

const AssetManagementSelectorCollectionsView = ({ collectionsList, className, onSelectCollection }: Props) => {
    if (!collectionsList) return null;
    if (!collectionsList.length) return null;

    return (
        <div className={classNames('asset-management-selector-collections-view', className)}>
            <GridWrapper targetSize={250}>
                {collectionsList.map((collection) => (
                    <AssetMangementSelectorCollectionTile key={`collection-${collection.id}`} collection={collection} onSelectCollection={onSelectCollection} />
                ))}
            </GridWrapper>
        </div>
    );
};

export default AssetManagementSelectorCollectionsView;
