import { AssetType } from 'types/asset.type';
import { StoreTypes } from 'types/store.type';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import ViewState from 'components/data/ViewState';
import Resources from 'components/data/Resources/components';
import {
    AssetManagementConfig,
    AssetManagementConfigContextType,
    AssetManagementConfigEnriched,
    AssetManagementConfigSetupResource
} from 'components/asset-management/types/asset-management-config.type';
import { AssetListState, AssetManagementStoreType } from '../types/asset-management-store.type';
import ComponentStoreHelpers from '../../data/ComponentStore';
import { getAssetFieldsWithOptions, getSubTypeItems, getAssetFilterSetup, getAssetFieldGroupingVerified } from '../utilities';
import { getCollectionFilterSetup } from '../utilities/getCollectionFilterSetup';
import { AssetManagementDisplayStateType } from '../context/asset-management-display-state.context';
import { SortField, SortDirection } from '../types/asset-management.service.type';
import { AssetManagementSetup } from '../types/asset-management-setup.type';
import { getRequiredAssetModels } from '../components/edit-asset/utilities';

const commonFileTypes = (accetptedFileTyesResources: string[], acceptedFileTypesConfig: string[]) => {
    return acceptedFileTypesConfig.filter((item1) => accetptedFileTyesResources.some((item2) => item1.toLowerCase() === item2.toLowerCase()));
};

const getAcceptedFileTypes = (acceptedFileTypesConfig?: string[], accetptedFileTyesResources?: string[]) => {
    if (!acceptedFileTypesConfig || !Array.isArray(acceptedFileTypesConfig)) return []; // API will not accept files for this media type.
    if (!accetptedFileTyesResources || !Array.isArray(accetptedFileTyesResources)) {
        return acceptedFileTypesConfig; // No file types are configured in the setup resource, so all file types that the API accepts are OK.
    }
    return commonFileTypes(accetptedFileTyesResources as string[], acceptedFileTypesConfig as string[]); // Only file types that are in both the setup resource and are accepted by the API.
};

const getInitialSortValue = (storeName: StoreTypes, model: string): SortField | SortDirection | undefined => {
    if (ViewState.get(storeName, model)) return ViewState.get(storeName, model);
    return undefined;
};

const getInitialListState = (storeName: StoreTypes, listModel: string): AssetListState => {
    const model = (() => {
        if (listModel.length > 0) return `${listModel}.`;
        return listModel;
    })();

    return {
        assetsList: null,
        assetsNextPageToken: 0,
        assetFilters: {},
        assetsCounts: {},
        assetSortField: getInitialSortValue(storeName, `${model}assetSortField`) as SortField | undefined,
        assetSortDirection: getInitialSortValue(storeName, `${model}assetSortDirection`) as SortDirection | undefined,
        totalAssetsCount: 0,
        filteredAssetsCount: 0
    };
};

/**
 * Asset manager helpers
 * This class contains some helpers functions for asset management
 */
class AssetManagementHelpers {
    static init = (AssetManagementConfig: AssetManagementConfig): void => {
        const { storeName } = AssetManagementConfig;

        ComponentStoreHelpers.setData<AssetManagementStoreType>(storeName, {
            ...getInitialListState(storeName, ''),
            assetsList: null,
            assetsNextPageToken: 0,
            assetFilters: {},
            assetsCounts: {},
            assetSortField: getInitialSortValue(storeName, 'assetSortField') as SortField | undefined,
            assetSortDirection: getInitialSortValue(storeName, 'assetSortDirection') as SortDirection | undefined,
            totalAssetsCount: 0,
            filteredAssetsCount: 0,
            collectionsList: null,
            collectionsNextPageToken: 0,
            collectionsContent: {},
            binned: getInitialListState(storeName, 'binned'),
            draft: getInitialListState(storeName, 'draft'),
            state: {
                collectionFilters: {},
                collectionSortField: getInitialSortValue(storeName, 'state.collectionSortField') as SortField | undefined,
                collectionSortDirection: getInitialSortValue(storeName, 'state.collectionSortDirection') as SortDirection | undefined,
                selectedAssetIds: [],
                selectedCollectionIds: []
            }
        });
    };

    static setInitialListState = (storeName: StoreTypes, listModel: string): void => {
        const listState = getInitialListState(storeName, listModel);
        ComponentStoreHelpers.setModel(storeName, listModel, listState);
    };

    static getDisplayState = (type: AssetType, setup: AssetManagementSetup): AssetManagementDisplayStateType => {
        const { displayType: displayTypeSetup } = setup;
        const storedDisplayType = ViewState.get(type, 'displayType');
        const storedFilterbarOpen = ViewState.get(type, 'filterbarOpen', 'session');
        const storedDetailbarOpen = ViewState.get(type, 'detailbarOpen', 'session');
        const storedCollectionsOpen = ViewState.get(type, 'collectionsOpen', 'session');

        // Get the initial display type from local storage, the setup resource or otherwise use the default display type.
        const displayType = (() => {
            if (storedDisplayType) return storedDisplayType;
            if (displayTypeSetup) return displayTypeSetup;
            return 'grid';
        })();

        // Get the initial filterbar open state from session storage or otherwise use true.
        const filterbarOpen = (() => {
            if (storedFilterbarOpen !== undefined) return storedFilterbarOpen;
            return true;
        })();

        // Get the initial detailbar open state from session storage or otherwise use false.
        const detailbarOpen = (() => {
            if (storedDetailbarOpen !== undefined) return storedDetailbarOpen;
            return false;
        })();

        const collectionsOpen = (() => {
            if (storedCollectionsOpen) return storedCollectionsOpen;
            return [];
        })();

        return {
            displayType,
            filterbarOpen,
            detailbarOpen,
            collectionsOpen
        };
    };

    static getEnrichedConfig = async (
        AssetManagementConfig: AssetManagementConfig,
        setup: AssetManagementSetup
    ): Promise<AssetManagementConfigEnriched | undefined> => {
        const { type, acceptedFileTypes: acceptedFileTypesConfig } = AssetManagementConfig;

        // We always want the collections tree available so we fetch it on init.
        const collectionsTreeResponse = await AssetManagementService.getCollectionsTree({
            type
        });
        const { structure: collectionsTree } = collectionsTreeResponse;

        const subTypeItems = getSubTypeItems(AssetManagementConfig);

        const {
            assetFields,
            assetFilterOrder,
            acceptedFileTypes: acceptedFileTypesResources,
            assetFieldsGrouping,
            maxFileSize,
            cropFormats,
            defaultPublic
        } = setup;

        const assetFieldsWithOptions = await getAssetFieldsWithOptions(assetFields, collectionsTree, subTypeItems, type);
        const assetFilterSetup = getAssetFilterSetup(assetFieldsWithOptions, assetFilterOrder);
        const collectionFilterSetup = getCollectionFilterSetup(assetFieldsWithOptions);
        const customAcceptedFiletypes = getAcceptedFileTypes(acceptedFileTypesConfig, acceptedFileTypesResources);
        const assetFieldsGroupingVerified = assetFieldsGrouping ? getAssetFieldGroupingVerified(assetFieldsGrouping, assetFieldsWithOptions) : [];
        const requiredAssetModels = getRequiredAssetModels(assetFieldsWithOptions);

        return {
            ...AssetManagementConfig,
            assetFieldsGrouping: assetFieldsGroupingVerified,
            assetFields: assetFieldsWithOptions,
            requiredAssetModels,
            assetFilterOrder,
            assetFilterSetup,
            collectionFilterSetup,
            collectionsTree,
            acceptedFileTypes: customAcceptedFiletypes, // For Media management. or future types that require uploads.
            maxFileSize, // For Media management. or future types that require uploads.
            cropFormats, // For Media.
            defaultPublic: Boolean(defaultPublic) // Store assets and collections as public by default.
        };
    };

    /**
     * If something is expected te be changed in fields and filters that get their data from the backend,
     * this function should be called to refresh them.
     **/
    static refreshFieldsAndFilters = async (AssetManagementConfigContext: AssetManagementConfigContextType): Promise<void> => {
        const { setAssetManagementConfig, ...AssetManagementConfig } = AssetManagementConfigContext;
        const { collectionsTree, type, assetFilterOrder, assetFields } = AssetManagementConfig;

        const subTypeItems = getSubTypeItems(AssetManagementConfig);

        const assetFieldsWithOptions = await getAssetFieldsWithOptions(assetFields, collectionsTree, subTypeItems, type);
        const assetFilterSetup = getAssetFilterSetup(assetFieldsWithOptions, assetFilterOrder);

        setAssetManagementConfig({
            ...AssetManagementConfig,
            assetFields: assetFieldsWithOptions,
            assetFilterSetup
        });
    };

    /**
     * Load the setup from the resources. If it is not found, use the default setup.
     */
    static getAssetManagementSetup = async (
        setupResource: AssetManagementConfigSetupResource,
        defaultSetup: AssetManagementSetup
    ): Promise<AssetManagementSetup> => {
        const setup: AssetManagementSetup = await Resources.asyncGetOrLoad(setupResource).catch(() => {
            return defaultSetup;
        });
        return setup;
    };
}

export default AssetManagementHelpers;
