import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AssetV2 } from 'types/asset.type';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Translation from 'components/data/Translation';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import { useAMFetch, useAMData, useAMAssetsNotValidated } from 'components/asset-management/hooks';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import useAMAssetSelection from 'components/asset-management/hooks/useAMAssetSelection';

interface Props {
    asset: AssetV2<unknown, unknown>;
    onClose: () => void;
    onSetWaiting: (waiting: boolean) => void;
}

const AssetManagementAssetMenuItemsSetAvailable = ({ asset, onClose, onSetWaiting }: Props) => {
    const { userHasRight, storeName } = useAssetManagementConfigContext();
    const { setSelectedAssets } = useAMAssetSelection();
    const { fetchAssets } = useAMFetch();
    const { data } = useAMData<{ selectedAssetIds: string[] }>({
        selectedAssetIds: 'state.selectedAssetIds'
    });
    const { selectedAssetIds } = data;
    const assetsNotValidated = useAMAssetsNotValidated([asset]);

    const setAvailable = async (asset: AssetV2<unknown, unknown>) => {
        onSetWaiting(true);
        const newAsset: AssetV2<unknown, unknown> = { ...asset, status: 'available' };
        const response = await AssetManagementService.patchAsset(newAsset);
        if (response) {
            setSelectedAssets(selectedAssetIds.filter((id: string) => id !== asset._id));
            await fetchAssets(true, 'draft');
            await fetchAssets(true, 'available');
            ComponentStoreHelpers.setModel(storeName, 'collectionsContent', {});
            onSetWaiting(false);
        }
    };

    if (!userHasRight('management')) return null;
    if (asset.status !== 'draft') return null;

    const disabled = Object.keys(assetsNotValidated).length > 0;

    return (
        <Tooltip title={disabled ? Translation.get('validation.allRequiredFields', 'common') : ''}>
            <span>
                <MenuItem
                    disabled={disabled}
                    onClick={(e) => {
                        e.stopPropagation();
                        setAvailable(asset);
                        onClose();
                    }}>
                    <ListItemIcon>
                        <Icon>check_circle_outline</Icon>
                    </ListItemIcon>
                    <ListItemText>{Translation.get('actions.setAvailable', 'asset-management')}</ListItemText>
                </MenuItem>
            </span>
        </Tooltip>
    );
};

export default AssetManagementAssetMenuItemsSetAvailable;
