import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Collection } from 'types/collection.type';
import { FilterSetup, Filters } from 'types/filter.type';
import CircularProgress from '@mui/material/CircularProgress';
import Loader from 'components/ui-components/Loader';
import InfiniteScroll from 'components/ui-components/InfiniteScroll';
import { FetchPayload, SortDirection, SortField } from 'components/asset-management/types/asset-management.service.type';
import Translation from 'components/data/Translation';
import Illustration from 'components/ui-components-cape/Illustration';
import EmptyState from 'components/ui-components-cape/EmptyState';
import { Memoize } from 'components/template-designer/components/ui-components/memoize/memoize';
import AssetManagementSelectorCollectionsView from './collections-view';
import { SimpleCollection } from '../types/simple-collection.type';
import AssetManagementSelectorFilterBar from './filter-bar';

import '../styles/collections.scss';

interface Props {
    collectionsList: Collection[] | null;
    filterSetup: FilterSetup;
    filters: Filters;
    sortField?: SortField;
    sortDirection?: SortDirection;
    hasMore: boolean;
    loading: boolean;
    onGetCollectionsContent: (refresh: boolean, payload?: FetchPayload) => void;
    onSelectCollection: (simpleCollection: SimpleCollection) => void;
    onChangeFilter: (filter: string, value: string | string[]) => void;
    onClearFilter: () => void;
}

const AssetManagementSelectorCollections = ({
    collectionsList,
    filterSetup,
    filters,
    sortField,
    sortDirection,
    hasMore,
    loading,
    onSelectCollection,
    onChangeFilter,
    onClearFilter,
    onGetCollectionsContent
}: Props) => {
    const [forceSearchTerm, setForceSearchTerm] = useState<string>('');
    const scrollParentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (filters.query && filters.query.length) {
            setForceSearchTerm(filters.query as string);
        }
    }, []);

    const sort = (sortField: SortField, sortDirection: SortDirection) => {
        onGetCollectionsContent(true, { sorting: { sortField, sortDirection } });
    };

    const loadMore = () => {
        onGetCollectionsContent(false);
    };

    return (
        <div className="asset-maagement-selector-collections">
            <AssetManagementSelectorFilterBar
                filterSetup={filterSetup}
                filters={filters}
                hideFilters={[]}
                sortField={sortField}
                sortDirection={sortDirection}
                forceSearchTerm={forceSearchTerm}
                onChangeFilter={onChangeFilter}
                onClearFilter={onClearFilter}
                onSort={sort}
            />
            <div
                className={classNames('asset-maagement-selector-collections__collections-list', {
                    'asset-maagement-selector-collections__collections-list--empty': collectionsList && collectionsList.length === 0
                })}
                ref={scrollParentRef}>
                <Loader isLoading={loading}>
                    {collectionsList && collectionsList.length > 0 && (
                        <Memoize valuesToCheck={collectionsList}>
                            <InfiniteScroll
                                loadMore={loadMore}
                                pageStart={collectionsList ? collectionsList.length / 20 : 0}
                                hasMore={hasMore}
                                initialLoad={false}
                                loader={
                                    <div className="asset-maagement-selector-collections__loader" key={0}>
                                        <CircularProgress />
                                    </div>
                                }
                                useWindow={false}
                                threshold={350}
                                getScrollParent={() => scrollParentRef.current}>
                                <AssetManagementSelectorCollectionsView collectionsList={collectionsList} onSelectCollection={onSelectCollection} />
                            </InfiniteScroll>
                        </Memoize>
                    )}
                    {collectionsList && collectionsList.length === 0 && (
                        <EmptyState
                            primaryText={Translation.get('feedback.emptyState.noResults', 'common')}
                            illustration={<Illustration illustration="no-result" color="disabled" />}
                        />
                    )}
                </Loader>
            </div>
        </div>
    );
};

export default AssetManagementSelectorCollections;
