import React from 'react';
import ViewState from 'components/data/ViewState';
import { useAMData } from 'components/asset-management/hooks/';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import { SortField, SortDirection } from '../../../types/asset-management.service.type';
import AssetManagementSortDropdownDisplay from './display';

interface Props {
    sortFieldModel: string;
    sortDirectionModel: string;
    fetchData: (refresh: boolean, payload: { sorting: { sortField: SortField; sortDirection: SortDirection } }) => void;
    className?: string;
}

interface Data {
    sortField: SortField;
    sortDirection: SortDirection;
}

const AssetManagementSortDropdown = ({ fetchData, sortFieldModel, sortDirectionModel, className }: Props) => {
    const { storeName } = useAssetManagementConfigContext();
    const { data } = useAMData<Data>({
        sortField: sortFieldModel,
        sortDirection: sortDirectionModel
    });
    const { sortField, sortDirection } = data;

    // Fetch data according to sort and remember sort locally.
    const sort = (sortField: SortField, sortDirection: SortDirection) => {
        fetchData(true, { sorting: { sortField, sortDirection } });
        ViewState.set(storeName, sortFieldModel, sortField);
        ViewState.set(storeName, sortDirectionModel, sortDirection);
    };

    return <AssetManagementSortDropdownDisplay sortField={sortField} sortDirection={sortDirection} className={className} onSort={sort} />;
};

export default AssetManagementSortDropdown;
