import React, { useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import WithContentSpaceHandler from 'components/asset-library/DataHandler';
import GenericFilter from 'components/ui-base/GenericFilter';
import Translation from 'components/data/Translation';
import ContentSpaceGenericFilterHelpers from '../helpers/';

const emptyCategoriesFilter = {
    name: 'categories',
    type: 'multiple',
    filterType: 'assets',
    options: [],
    selection: []
};

const GenericFilterWrapper = ({
    children,
    filters,
    locationView,
    filterType,
    reviewCount,
    binnedCount,
    className,
    left,
    fixedList,
    inDialog,
    searchQuery,
    acceptedTypes,
    assetFilterOrder,
    forceSearchTerm,
    filtersFetched,
    onToggleFilterSelection,
    onClearFilterSelection,
    onClearAllFilterSelection,
    onSearchAssets,
    onSearchCollections,
    onSearchCollectionItems,
    onCallBack = () => {}
}) => {
    const [filterSetup, setFilterSetup] = useState([]);
    const [currentFilters, setCurrentFilters] = useState({});
    const [searchTerm, setSearchTerm] = useState(searchQuery?.query || '');

    /**
     * Change a filter setting
     * @param {string} key
     * @param {array} value
     */
    const changeFilter = (key, value) => {
        ContentSpaceGenericFilterHelpers.changeFilter(key, value, filters, onClearFilterSelection, onToggleFilterSelection);
    };

    /**
     * Prefrom a text search on either assets o collections.
     * @param {string} term
     */
    const search = (term) => {
        if (searchTerm !== term) {
            setSearchTerm(term);
            if (locationView === 'collections') {
                onSearchCollections(term);
            } else if (locationView === 'collectionItems') {
                onSearchCollectionItems(term);
            } else {
                onSearchAssets(term);
            }
        }
    };

    /**
     * If acceptedTypes are defined, set the categories filter on these accepted types.
     * @param {string|array} acceptedTypes
     * @param {array} filters
     * @param {function} onToggleFilterSelection
     */
    const handleAcceptedTypes = (acceptedTypes, filters, onToggleFilterSelection) => {
        // If acceptedTypes are specified, make sure the categories filter is set to these acceptedTypes.
        if (!!acceptedTypes && acceptedTypes.length > 0) {
            let thisFilter = filters.find((filter) => filter.name === 'categories');
            const thisSelection = Array.isArray(acceptedTypes) ? acceptedTypes : [acceptedTypes];

            // If there's no category filter defined in the resources. Create a dummy one to make filtering possible.
            if (!thisFilter) {
                thisFilter = emptyCategoriesFilter;
            }

            if (!isEqual(thisFilter.selection, thisSelection)) {
                onToggleFilterSelection(thisFilter, thisSelection);
            }
        }
    };

    /**
     * If the ContentSpace filters change, remap them to the filters and filterSetup the GenericFilter uses.
     */
    useEffect(() => {
        setFilterSetup(
            ContentSpaceGenericFilterHelpers.getFilterSetup(filters, locationView, filterType, reviewCount, binnedCount, acceptedTypes, assetFilterOrder)
        );
        setCurrentFilters(ContentSpaceGenericFilterHelpers.getFilters(filters, locationView));

        handleAcceptedTypes(acceptedTypes, filters, onToggleFilterSelection);
    }, [filters]);

    /**
     * When the reviewCount or binnedCount changes, update the filterSetup with these values.
     */
    useEffect(() => {
        if (locationView === 'review' || locationView === 'binned') {
            setFilterSetup(ContentSpaceGenericFilterHelpers.getFilterSetup(filters, locationView, filterType, reviewCount, binnedCount, assetFilterOrder));
        }
    }, [reviewCount, binnedCount]);

    /**
     * Pass changed filterSetup back to the parent with a callback funtion.
     */
    useEffect(() => {
        onCallBack({ genericFilterSetup: filterSetup });
    }, [filterSetup]);

    /**
     * Pass changed filters back to the parent with a callback funtion.
     */
    useEffect(() => {
        onCallBack({ genericFilters: currentFilters });
    }, [currentFilters]);

    useEffect(() => {
        setSearchTerm(searchQuery?.query || '');
    }, [locationView]);

    return (
        <GenericFilter
            key={`generic-filter-${locationView}`}
            left={left}
            inDialog={inDialog}
            fixedList={fixedList}
            searchField
            searchTerm={searchTerm}
            searchPlaceholder={
                filterType === 'collections'
                    ? Translation.get('labels.searchCollections', 'content-space')
                    : Translation.get('labels.searchAssets', 'content-space')
            }
            filterSetup={filterSetup}
            filters={currentFilters}
            filtersLoading={!filtersFetched}
            className={className}
            forceSearchTerm={forceSearchTerm}
            onSearch={search}
            onClearAll={() => onClearAllFilterSelection(filterType)}
            onChangeFilter={changeFilter}
            ignoreInFiltersCount={['categories']}>
            {children && children}
        </GenericFilter>
    );
};

export default WithContentSpaceHandler(GenericFilterWrapper);
