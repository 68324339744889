import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from 'components/ui-components-v2/Select';
import MenuItem from 'components/ui-components-v2/MenuItem';
import ViewState from 'components/data/ViewState';
import Translation from 'components/data/Translation';

import '../styles/sort.scss';

const options = {
    'createdAt-1': {
        sortField: 'createdAt',
        sortDirection: '-1',
        label: Translation.get('sort.createdDown', 'content-space')
    },
    'createdAt+1': {
        sortField: 'createdAt',
        sortDirection: '1',
        label: Translation.get('sort.createdUp', 'content-space')
    },
    'name+1': {
        sortField: 'title',
        sortDirection: '-1',
        label: Translation.get('sort.titleDown', 'content-space')
    },
    'name-1': {
        sortField: 'title',
        sortDirection: '1',
        label: Translation.get('sort.titleUp', 'content-space')
    }
};

/**
 * Get the current sorting value
 * @param {object} sorting
 * @returns
 */
const getValue = (sorting = {}) => {
    let result = Object.keys(options)[0];

    //If sorting is empty (on initial load) check for filters saved in local storage
    if (Object.keys(sorting).length === 0) sorting = ViewState.get('contentSpace', 'sorting', 'local') || {};

    if (sorting && sorting.sortField && sorting.sortDirection) {
        Object.entries(options).forEach(([key, value]) => {
            if (value.sortField === sorting.sortField && value.sortDirection === sorting.sortDirection) {
                result = key;
            }
        });
    }
    return result;
};

const AssetsSort = ({ sorting, sortAssets, className }) => {
    const [value, setValue] = useState(getValue(sorting));

    /**
     * Sort the assets
     * @param {*} e
     */
    const sort = (e) => {
        const newSort = { ...options[e.target.value] };
        delete newSort.label;

        // Save selected filter with view state
        ViewState.set('contentSpace', 'sorting', newSort, 'local');
        sortAssets(newSort);
    };

    useEffect(() => {
        setValue(getValue(sorting));
    }, [sorting]);

    return (
        <FormControl variant="outlined" className={classNames('asset-library-assets-sort', className)} data-tour-selector="assets-list-sort">
            <InputLabel margin="dense" htmlFor={'assets-sort-select'}>
                Sort
            </InputLabel>
            <Select
                margin="dense"
                value={value}
                label="Sort"
                onChange={(e) => sort(e)}
                inputProps={{
                    id: 'assets-sort-select'
                }}
                classes={{
                    select: 'asset-library-assets-sort__select'
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                }}>
                {Object.entries(options).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                        {value.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default AssetsSort;
