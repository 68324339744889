import React from 'react';
import classNames from 'classnames';

import '../styles/tabs.scss';

interface Props {
    tabs: { label: string; value: string }[] | null;
    activeTab: string;
    className?: string;
    onChange: (value: string) => void;
}

const AssetManagementSelectorTabs = ({ tabs, activeTab, className, onChange }: Props) => {
    if (!tabs?.length) return null;

    return (
        <div className={classNames('asset-management-selector-tabs', className)}>
            {tabs.map((tab) => (
                <div
                    key={tab.value}
                    onClick={() => onChange(tab.value)}
                    className={classNames('asset-management-selector-tabs__tab', { 'asset-management-selector-tabs__tab--active': activeTab === tab.value })}>
                    <span>{tab.label}</span>
                </div>
            ))}
        </div>
    );
};

export default AssetManagementSelectorTabs;
