import React, { createContext, useContext, ReactNode, useState } from 'react';
import { MEDIA_MANAGEMENT_CONFIG } from 'components/media-management/constants';
import User from 'components/data/User';
import { AssetManagementConfigEnriched, AssetManagementConfigContextType } from '../types/asset-management-config.type';
import { getLanguageNameSpace } from '../utilities';
import { Right } from '../types/asset-management-right.type';

const AssetManagementConfigContext = createContext<AssetManagementConfigContextType>({
    ...MEDIA_MANAGEMENT_CONFIG, // We need a defaut value here. Can be anything, but we use MEDIA_MANAGEMENT_CONFIG
    assetFieldsGrouping: [],
    assetFields: [],
    requiredAssetModels: [],
    assetFilterSetup: [],
    collectionFilterSetup: [],
    collectionsTree: [],
    defaultPublic: false,
    setAssetManagementConfig: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function,
    languageNameSpace: '',
    userHasRight: (requiredRight: Right) => false // eslint-disable-line @typescript-eslint/no-unused-vars
});
AssetManagementConfigContext.displayName = 'AssetAssetManagementConfigContext';

interface Props {
    children: ReactNode;
    config: AssetManagementConfigEnriched;
}

const useAssetManagementConfigContext = (): AssetManagementConfigContextType => {
    return useContext(AssetManagementConfigContext);
};

const AssetManagementConfigProvider = ({ children, config }: Props) => {
    const [assetManagementConfig, setAssetManagementConfig] = useState<AssetManagementConfigEnriched>(config);

    const languageNameSpace = getLanguageNameSpace(assetManagementConfig.type);

    // Determine the rights for this specific asset type.
    const userHasRight = (requiredRight: Right): boolean => {
        const capitalize = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);
        const rootRight = `assetManager${capitalize(assetManagementConfig.type)}`;
        const typedRight = (r: string) => `${rootRight}${capitalize(r)}`;

        // we have to check here the rights on a specific way to make sure the hierarchy is respected
        // The hierachy is as follows:
        // 1. management
        // 2. write
        // 3. read
        // if you have management rights, you have all rights
        if (User.hasRight(typedRight('management'))) return true;
        // if you have write rights, you have read rights so we need to check here if the required right is write or read and if the user has write rights
        if (['write', 'read'].includes(requiredRight) && User.hasRight(typedRight('write'))) return true;
        // if you have read rights, you only have read rights
        if (requiredRight === 'read') return User.hasRight(typedRight('read'));
        return false;
    };

    const contextValue = {
        ...assetManagementConfig,
        setAssetManagementConfig,
        languageNameSpace,
        userHasRight
    };
    return <AssetManagementConfigContext.Provider value={contextValue}>{children}</AssetManagementConfigContext.Provider>;
};

export default AssetManagementConfigProvider;
export { useAssetManagementConfigContext, AssetManagementConfigContext, AssetManagementConfigProvider };
