// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../images/gradient_large.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/* Units are deprecated, use the $s* variables instead */\n/* Sizes using rem. This is based on a default 16px */\n/* This is to provide better control and consistency in managing stacking components */\n/* Custom sizes */\n.asset-management-collection-tile-thumbnails-placeholder {\n  display: flex;\n  justify-content: center;\n  height: 100%;\n  width: 100%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}", ""]);
// Exports
module.exports = exports;
