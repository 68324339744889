import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    clearFilterSelection,
    clearAllFilterSelection,
    fetchAssets,
    toggleFilterSelection,
    setAllFilterSelection,
    toggleAssetSelection,
    setAssetSelection,
    addAssets,
    deleteAssets,
    approveAssets,
    addAssetMetadata,
    addAssetsMetadataBatch,
    fetchFilters,
    fetchCollections,
    fetchAllCollections,
    addAssetToCollection,
    addCollectionToCollection,
    fetchCollectionItems,
    fetchMoreCollectionItems,
    fetchConcepts,
    createCollection,
    removeCollectionItem,
    removeCollection,
    addCollectionMetadata,
    fetchToken,
    fetchMoreAssets,
    fetchMoreCollections,
    onSearchAssets,
    onSearchCollections,
    onSearchCollectionItems,
    setResources,
    setLocationView,
    setConcepts,
    fetchAssetCount,
    setCollectionReferenceId,
    setSavedAssets,
    resetSavedAssets,
    resetSearchQuery,
    sortAssets,
    emptyBin,
    unbinAssets,
    setFilterExpired
} from 'reducers/ContentSpace/actions';

export default function WithContentSpaceHandler(WrappedComponent) {
    const ContentSpaceHandlerComponent = class extends Component {
        static mapStateToProps = (state) => {
            return {
                assetFilterOrder: state.contentspace.assetFilterOrder,
                filters: state.contentspace.filters,
                items: state.contentspace.items,
                uploadsInProgress: state.contentspace.uploadsInProgress,
                uploadsInProgressFetched: state.contentspace.uploadsInProgressFetched,
                assetsFetched: state.contentspace.assetsFetched,
                filtersFetched: state.contentspace.filtersFetched,
                filterExpired: state.contentspace.filterExpired,
                collectionItems: state.contentspace.collectionItems,
                collectionSubcollections: state.contentspace.collectionSubcollections,
                selectedAssets: state.contentspace.selectedAssets,
                collections: state.contentspace.collections,
                collectionsFetched: state.contentspace.collectionsFetched,
                collectionsFiltered: state.contentspace.collectionsFiltered,
                metadataInputs: state.contentspace.metadataInputs,
                metadataInputsRequired: state.contentspace.metadataInputsRequired,
                metadataInputsGrouped: state.contentspace.metadataInputsGrouped,
                collectionMetadataInputs: state.contentspace.collectionMetadataInputs,
                token: state.contentspace.token,
                searchQuery: state.contentspace.searchQuery,
                itemsNext: state.contentspace.itemsNext,
                collectionsNext: state.contentspace.collectionsNext,
                collectionItemsNext: state.contentspace.collectionItemsNext,
                locationView: state.contentspace.locationView,
                reviewCount: state.contentspace.reviewCount,
                binnedCount: state.contentspace.binnedCount,
                collectionReferenceId: state.contentspace.collectionReferenceId,
                savedAssets: state.contentspace.savedAssets,
                collectionItemsFiltered: state.contentspace.collectionItemsFiltered,
                assetSorting: state.contentspace.assetSorting,
                collectionItemsSorting: state.contentspace.collectionItemsSorting,
                accept: state.contentspace.accept,
                allCollections: state.contentspace.allCollections,
                allCollectionsFetched: state.contentspace.allCollectionsFetched
            };
        };

        onClearFilterSelection = (filterKey, filterType) => {
            this.props.clearFilterSelection(filterKey, filterType);
        };

        onToggleFilterSelection = (filter, filterKey) => {
            this.props.toggleFilterSelection(filter, filterKey);
        };

        onSetAllFilterSelection = (filters, filterKey, refresh) => {
            this.props.setAllFilterSelection(filters, filterKey, refresh);
        };

        onClearAllFilterSelection = (filterType) => {
            this.props.clearAllFilterSelection(filterType);
        };

        onToggleAssetSelection = (asset) => {
            this.props.toggleAssetSelection(asset);
        };

        onSetAssetSelection = (assetSelection) => {
            this.props.setAssetSelection(assetSelection);
        };

        onAddAssets = (assets) => {
            this.props.addAssets(assets);
        };

        onDeleteAssets = (assets, type = 'bin') => {
            this.props.deleteAssets(assets, type);
        };

        onApproveAssets = (assets) => {
            this.props.approveAssets(assets);
        };

        onAddAssetMetadata = (assetId, data) => {
            this.props.addAssetMetadata(assetId, data);
        };

        onAddAssetsMetadataBatch = (data) => {
            this.props.addAssetsMetadataBatch(data);
        };

        fetchFilters = (refresh = false) => {
            this.props.fetchFilters(refresh);
        };

        fetchCollections = () => {
            this.props.fetchCollections();
        };

        fetchAllCollections = () => {
            this.props.fetchAllCollections();
        };

        fetchConcepts = () => {
            this.props.fetchConcepts();
        };

        setConcepts = (uploadsInProgress) => {
            this.props.setConcepts(uploadsInProgress);
        };

        addAssetToCollection = (collectionReferenceId, assetReferenceId) => {
            this.props.addAssetToCollection(collectionReferenceId, assetReferenceId);
        };

        addCollectionToCollection = (collectionReferenceId, targetId) => {
            this.props.addCollectionToCollection(collectionReferenceId, targetId);
        };

        fetchCollectionItems = (collectionReferenceId) => {
            this.props.fetchCollectionItems(collectionReferenceId);
        };

        fetchMoreCollectionItems = (collectionReferenceId) => {
            this.props.fetchMoreCollectionItems(collectionReferenceId);
        };

        // eslint-disable-next-line require-await
        createCollection = async (name, thumbnail, collectionParentId) => {
            return this.props.createCollection(name, thumbnail, collectionParentId);
        };

        removeCollectionItem = (collectionReferenceId, assetReferenceId) => {
            this.props.removeCollectionItem(collectionReferenceId, assetReferenceId);
        };

        removeCollection = (collectionReferenceId) => {
            this.props.removeCollection(collectionReferenceId);
        };

        addCollectionMetadata = (value, key, id) => {
            this.props.addCollectionMetadata(value, key, id);
        };

        fetchToken = () => {
            this.props.fetchToken();
        };

        fetchMoreAssets = () => {
            this.props.fetchMoreAssets();
        };

        fetchMoreCollections = () => {
            this.props.fetchMoreCollections();
        };

        onSearchAssets = (value) => {
            this.props.onSearchAssets(value);
        };

        onSearchCollections = (value) => {
            this.props.onSearchCollections(value);
        };

        onSearchCollectionItems = (value) => {
            this.props.onSearchCollectionItems(value);
        };

        setResources = (value) => {
            this.props.setResources(value);
        };

        setLocationView = (value) => {
            this.props.setLocationView(value);
        };

        fetchAssetCount = () => {
            this.props.fetchAssetCount();
        };

        setCollectionReferenceId = (value) => {
            this.props.setCollectionReferenceId(value);
        };

        resetSavedAssets = () => {
            this.props.resetSavedAssets();
        };

        resetSearchQuery = () => {
            this.props.resetSearchQuery();
        };

        sortAssets = (sorting) => {
            this.props.sortAssets(sorting);
        };

        emptyBin = () => {
            this.props.emptyBin();
        };

        onUnbinAssets = (assets) => {
            this.props.unbinAssets(assets);
        };

        setFilterExpired = (value = false) => {
            this.props.setFilterExpired(value);
        };

        render() {
            const extraProps = {
                onToggleFilterSelection: this.onToggleFilterSelection,
                onSetAllFilterSelection: this.onSetAllFilterSelection,
                onClearFilterSelection: this.onClearFilterSelection,
                onClearAllFilterSelection: this.onClearAllFilterSelection,
                onToggleAssetSelection: this.onToggleAssetSelection,
                onSetAssetSelection: this.onSetAssetSelection,
                onAddAssets: this.onAddAssets,
                onDeleteAssets: this.onDeleteAssets,
                onUnbinAssets: this.onUnbinAssets,
                onApproveAssets: this.onApproveAssets,
                onAddAssetMetadata: this.onAddAssetMetadata,
                onAddAssetsMetadataBatch: this.onAddAssetsMetadataBatch,
                fetchAssets: this.fetchAssets,
                fetchFilters: this.fetchFilters,
                fetchCollections: this.fetchCollections,
                fetchAllCollections: this.fetchAllCollections,
                addAssetToCollection: this.addAssetToCollection,
                addCollectionToCollection: this.addCollectionToCollection,
                fetchCollectionItems: this.fetchCollectionItems,
                fetchMoreCollectionItems: this.fetchMoreCollectionItems,
                createCollection: this.createCollection,
                removeCollectionItem: this.removeCollectionItem,
                removeCollection: this.removeCollection,
                addCollectionMetadata: this.addCollectionMetadata,
                fetchToken: this.fetchToken,
                fetchMoreAssets: this.fetchMoreAssets,
                fetchMoreCollections: this.fetchMoreCollections,
                onSearchAssets: this.onSearchAssets,
                onSearchCollections: this.onSearchCollections,
                onSearchCollectionItems: this.onSearchCollectionItems,
                setResources: this.setResources,
                setLocationView: this.setLocationView,
                fetchAssetCount: this.fetchAssetCount,
                setCollectionReferenceId: this.setCollectionReferenceId,
                fetchConcepts: this.fetchConcepts,
                setConcepts: this.setConcepts,
                setFilterExpired: this.setFilterExpired
            };
            return <WrappedComponent {...extraProps} {...this.props} />;
        }
    };

    ContentSpaceHandlerComponent.displayName = 'ContentSpaceHandlerComponent';

    return connect(ContentSpaceHandlerComponent.mapStateToProps, {
        clearFilterSelection,
        toggleFilterSelection,
        setAllFilterSelection,
        clearAllFilterSelection,
        toggleAssetSelection,
        setAssetSelection,
        addAssets,
        deleteAssets,
        approveAssets,
        addAssetMetadata,
        addAssetsMetadataBatch,
        fetchAssets,
        fetchFilters,
        fetchCollections,
        fetchAllCollections,
        fetchConcepts,
        addAssetToCollection,
        addCollectionToCollection,
        fetchCollectionItems,
        fetchMoreCollectionItems,
        createCollection,
        removeCollectionItem,
        removeCollection,
        addCollectionMetadata,
        fetchToken,
        fetchMoreAssets,
        fetchMoreCollections,
        onSearchAssets,
        onSearchCollections,
        onSearchCollectionItems,
        setResources,
        setLocationView,
        fetchAssetCount,
        setCollectionReferenceId,
        setSavedAssets,
        setConcepts,
        resetSavedAssets,
        resetSearchQuery,
        sortAssets,
        emptyBin,
        unbinAssets,
        setFilterExpired
    })(ContentSpaceHandlerComponent);
}
