import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { set } from 'lodash';
import { AssetSubType, AssetType, AssetV2 } from 'types/asset.type';
import { FilterSetup, Filters } from 'types/filter.type';
import { useInCampaign } from 'hooks/useInCampaign';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import {
    AssetListPayload,
    AssetListResponse,
    CollectionListPayload,
    FetchPayload,
    MetricsUsageUseCase,
    SortDirection,
    SortField
} from 'components/asset-management/types/asset-management.service.type';
import {
    getAssetListStateFromResponse,
    getChangedFilter,
    getCollectionOptionsFromTree,
    getCurrentFilterSetup,
    getListParams,
    transformAssetParamsForRootKeys
} from 'components/asset-management/utilities';
import Translation from 'components/data/Translation';
import { AssetListState, CollectionListState } from 'components/asset-management/types/asset-management-store.type';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import EditorData from 'components/editor-data/EditorData';
import { SimpleCollection } from '../types/simple-collection.type';
import AssetManagementSelectorAssets from './assets';
import AssetManagementSelectorCollections from './collections';
import AssetManagementSelectorCollection from './collection';
import AssetManagementSelectorTabs from './tabs';

import '../styles/main.scss';

const SPECIAL_FIELDS = { market: 'markets', brand: 'brands', department: 'departments' };

interface Props {
    dataFilters?: {
        [key: string]: string | string[] | boolean;
    };
    preFilters?: Filters;
    subType?: AssetSubType[];
    addedContent?: ReactElement;
    collectionQuery?: string;
    objectName?: MetricsUsageUseCase | null;
    objectId?: string | number | null;
    type: AssetType;
    assetTile: ReactElement;
    hideTabs?: boolean;
    getAssetsAlternative?: (params: AssetListPayload, filterExpired?: boolean) => Promise<AssetListResponse>;
    onSelect: (item: AssetV2<unknown, unknown>) => void;
}

type CollectionListStateComplete = CollectionListState & {
    collectionFilters: Filters;
    collectionSortField?: SortField;
    collectionSortDirection?: SortDirection;
};

const ASSET_STATUS = 'available';
const CATEGORIES_KEY = 'categories';

const AssetManagementSelector = ({
    dataFilters,
    preFilters = {},
    subType,
    addedContent,
    collectionQuery,
    objectName,
    objectId,
    assetTile,
    type,
    hideTabs,
    getAssetsAlternative,
    onSelect
}: Props) => {
    const inCampaign = useInCampaign();
    const { assetFilterSetup, collectionsTree, collectionFilterSetup, languageNameSpace } = useAssetManagementConfigContext();
    const [tab, setTab] = useState<'assets' | 'collections'>('assets');
    const [categories, setCategories] = useState<string[] | null>(null);
    const [listState, setListState] = useState<AssetListState>({
        assetsList: null,
        assetsNextPageToken: 0,
        assetsCounts: {},
        assetFilters: preFilters,
        assetSortField: undefined,
        assetSortDirection: undefined,
        totalAssetsCount: 0,
        filteredAssetsCount: 0
    });
    const [collectionsListState, setCollectionsListState] = useState<CollectionListStateComplete>({
        collectionsList: null,
        collectionsNextPageToken: 0,
        collectionFilters: {},
        collectionSortField: undefined,
        collectionSortDirection: undefined
    });
    const [openCollection, setOpenCollection] = useState<SimpleCollection | null>(null);
    const [currentFilterSetup, setCurrentFilterSetup] = useState<FilterSetup>(assetFilterSetup);
    const [assetsLoading, setAssetsLoading] = useState(true);
    const [collectionsLoading, setCollectionsLoading] = useState(true);
    const allCollections = useMemo(() => getCollectionOptionsFromTree(collectionsTree), [collectionsTree]);
    const hideFilters = subType ? ['subType', CATEGORIES_KEY] : [CATEGORIES_KEY];

    useEffect(() => {
        if (!listState.assetsList) {
            getAssetsContent(true);
        }
        if (collectionQuery && typeof collectionQuery === 'string') {
            const foundCollection = allCollections.find((col) => col.label.toLowerCase().includes(collectionQuery.toLowerCase())) || null;
            setOpenCollection(foundCollection ? { id: foundCollection.value, title: foundCollection.label } : null);
        }
    }, []);

    const getAssetsContent = (refresh: boolean, payload?: FetchPayload) => {
        const shouldRefresh = Boolean(refresh || payload);
        if (shouldRefresh) setAssetsLoading(true);

        // Do not wait for the API to set the choosen filters.
        setListState((prevListState: AssetListState) => {
            return { ...prevListState, assetFilters: payload?.filters || {} };
        });

        const params: AssetListPayload = getListParams(
            type,
            {
                stateList: listState.assetsList,
                stateNextPageToken: listState.assetsNextPageToken,
                stateFilters: listState.assetFilters,
                stateSortField: listState.assetSortField,
                stateSortDirection: listState.assetSortDirection
            },
            ASSET_STATUS,
            shouldRefresh,
            payload
        );
        if (subType?.length) set(params, 'filters.subType', subType);
        if (inCampaign) {
            // When we're editing a campaign or campaign concept, we might need to filter on the special fields tied to that campain.
            Object.entries(SPECIAL_FIELDS).forEach(([key, value]) => {
                const campaignValue = EditorData.get(key);
                if (campaignValue?.length) {
                    hideFilters.push(value);
                    set(params, `filters.${value}`, [campaignValue, '_unassigned']);
                }
            });
        }
        if (dataFilters && Object.keys(dataFilters).length) set(params, 'dataFilters', dataFilters);
        if (objectName && objectId) {
            set(params, 'objectName', objectName);
            set(params, 'objectId', objectId);
        }
        const transformedAssetsParams = transformAssetParamsForRootKeys(params);

        const getAssets = getAssetsAlternative || AssetManagementService.getAssetBatch;
        getAssets(transformedAssetsParams, true).then((response) => {
            setListState((prevListState: AssetListState) => {
                if (subType && params.filters?.subType) delete params.filters.subType;
                const newListState = getAssetListStateFromResponse(response, prevListState.assetsList || [], shouldRefresh, params);
                return { ...prevListState, ...newListState };
            });
            if (!categories && response.counts[CATEGORIES_KEY]) {
                setCategories(Object.keys(response.counts[CATEGORIES_KEY])); // Set categories for display if categories where found in the first response
            }
            setCurrentFilterSetup(getCurrentFilterSetup(assetFilterSetup, response.counts, params.filters || {}, hideFilters));
            setAssetsLoading(false);
        });
    };

    const changeAssetsFilter = (key: string, value: string | string[]) => {
        const filters = getChangedFilter(listState.assetFilters, key, value);
        getAssetsContent(true, { filters });
    };

    const clearAssetsFilter = () => {
        getAssetsContent(true, { filters: {} });
    };

    const getCollectionsContent = (refresh: boolean, payload?: FetchPayload) => {
        const shouldRefresh = Boolean(refresh || payload);
        if (shouldRefresh) setCollectionsLoading(true);
        const params: CollectionListPayload = getListParams(
            type,
            {
                stateList: collectionsListState.collectionsList,
                stateNextPageToken: collectionsListState.collectionsNextPageToken,
                stateFilters: collectionsListState.collectionFilters,
                stateSortField: collectionsListState.collectionSortField,
                stateSortDirection: collectionsListState.collectionSortDirection
            },
            ASSET_STATUS,
            shouldRefresh,
            payload
        );
        if (objectName && objectId) {
            set(params, 'objectName', objectName);
            set(params, 'objectId', objectId);
        }
        AssetManagementService.getCollectionBatch(params, true).then((response) => {
            const { collections, nextPageToken } = response;
            setCollectionsListState((prevListState: CollectionListStateComplete) => {
                return {
                    collectionsList: shouldRefresh ? response.collections : [...(prevListState.collectionsList || []), ...collections],
                    collectionsNextPageToken: nextPageToken,
                    collectionFilters: params.filters || {},
                    collectionSortField: params.sortField,
                    collectionSortDirection: params.sortDirection
                };
            });
            setCollectionsLoading(false);
        });
    };

    const changeCollectionsFilter = (key: string, value: string | string[]) => {
        const filters = getChangedFilter(collectionsListState.collectionFilters, key, value);
        getCollectionsContent(true, { filters });
    };

    const clearCollectionsFilter = () => {
        getCollectionsContent(true, { filters: {} });
    };

    const changeTab = (tab: 'assets' | 'collections') => {
        setTab(tab);
        if (tab === 'collections' && !collectionsListState.collectionsList) {
            getCollectionsContent(true);
        }
    };

    const selectCollectionById = (collectionId?: string) => {
        const reset = () => {
            setOpenCollection(null);
            changeTab('collections');
        };

        if (!collectionId) {
            reset();
            return;
        }

        const foundCollection = allCollections.find((col) => col.value === collectionId) || null;
        if (!foundCollection) {
            reset();
            return;
        }

        setOpenCollection({ id: foundCollection.value, title: foundCollection.label });
    };

    return (
        <div className="asset-management-selector">
            {!openCollection && (
                <React.Fragment>
                    {!hideTabs && (
                        <AssetManagementSelectorTabs
                            tabs={[
                                {
                                    label:
                                        subType && subType.length === 1
                                            ? Translation.get(`sub_types.${subType[0]}`, languageNameSpace, { count: 2 })
                                            : Translation.get('asset_other', languageNameSpace),
                                    value: 'assets'
                                },
                                {
                                    label: Translation.get('labels.collections', 'asset-management'),
                                    value: 'collections'
                                }
                            ]}
                            activeTab={tab}
                            className="asset-management-selector__navigation"
                            onChange={changeTab as (value: string) => void}
                        />
                    )}
                    {tab === 'assets' && (
                        <AssetManagementSelectorAssets
                            assetsList={listState.assetsList}
                            categories={categories}
                            addedContent={addedContent}
                            hideFilters={hideFilters}
                            hasMore={Boolean(listState.assetsNextPageToken)}
                            filters={listState.assetFilters}
                            filterSetup={currentFilterSetup}
                            sortField={listState.assetSortField}
                            sortDirection={listState.assetSortDirection}
                            loading={assetsLoading}
                            assetTile={assetTile}
                            onSelect={onSelect}
                            onChangeFilter={changeAssetsFilter}
                            onClearFilter={clearAssetsFilter}
                            onGetAssetsContent={getAssetsContent}
                        />
                    )}
                    {tab === 'collections' && (
                        <AssetManagementSelectorCollections
                            collectionsList={collectionsListState.collectionsList}
                            filters={collectionsListState.collectionFilters}
                            filterSetup={collectionFilterSetup}
                            hasMore={Boolean(collectionsListState.collectionsNextPageToken)}
                            sortField={collectionsListState.collectionSortField}
                            sortDirection={collectionsListState.collectionSortDirection}
                            loading={collectionsLoading}
                            onSelectCollection={setOpenCollection}
                            onChangeFilter={changeCollectionsFilter}
                            onClearFilter={clearCollectionsFilter}
                            onGetCollectionsContent={getCollectionsContent}
                        />
                    )}
                </React.Fragment>
            )}
            {openCollection && (
                <AssetManagementSelectorCollection
                    dataFilters={dataFilters}
                    subType={subType}
                    categories={categories}
                    hideFilters={hideFilters}
                    objectName={objectName}
                    objectId={objectId}
                    openCollection={openCollection}
                    assetTile={assetTile}
                    onSelect={onSelect}
                    onSelectCollection={setOpenCollection}
                    onSelectCollectionById={selectCollectionById}
                />
            )}
        </div>
    );
};

export default AssetManagementSelector;
