import React from 'react';
import { Collection } from 'types/collection.type';
import { AssetV2 } from 'types/asset.type';
import classNames from 'classnames';
import Checkbox from 'components/ui-components-v2/Checkbox';
import useAMSelection from 'components/asset-management/hooks/useAMAssetSelection';
import useAMCollectionSelection from '../hooks/useAMCollectionSelection';

interface Props {
    asset?: AssetV2<unknown, unknown>;
    collection?: Collection;
    className?: string;
    muiColorScheme?: string;
    color?: string;
}

const AMCheckbox = ({ asset, collection, className = '', muiColorScheme, color }: Props) => {
    const assetSelection = useAMSelection(asset?._id);
    const collectionSelection = useAMCollectionSelection(collection?.id);

    const checked = !!((asset && assetSelection.isSelected) || (collection && collectionSelection.isSelected));

    const checkboxColor: any = (() => {
        if (color) return color;

        return checked ? 'primary' : 'default';
    })();

    return (
        <Checkbox
            data-tour-selector="asset-checkbox"
            data-tour-is-selected={checked}
            onClick={(e) => {
                e.stopPropagation();
                if (asset) assetSelection.selectAsset(asset._id);
                if (collection) collectionSelection.selectCollection(collection.id);
            }}
            checked={checked}
            color={checkboxColor}
            data-mui-color-scheme={muiColorScheme}
            className={classNames(className, {
                [`${className}--selected`]: checked
            })}
        />
    );
};

export default AMCheckbox;
