import React from 'react';
import { AssetV2 } from 'types/asset.type';
import Button from 'components/ui-components-v2/Button';
import Illustration from 'components/ui-components-cape/Illustration';
import Translation from 'components/data/Translation';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import { SelectorAsset } from '../types/selector-asset.type';

import '../styles/tile.scss';

interface Props {
    item?: SelectorAsset;
    onSelect?: (asset: AssetV2<unknown, unknown>) => void;
}

const AsetManagementSelectorTile = ({ item, onSelect }: Props) => {
    const { assetsPlaceholder } = useAssetManagementConfigContext();
    if (!item) return null;
    if (!onSelect) return null;

    return (
        <div className="asset-management-selector-tile" onClick={() => onSelect(item.originalRecord)}>
            <div className="asset-management-selector-tile__preview">
                {item.thumbnail ? (
                    <div className="asset-management-selector-tile__image">
                        <img src={item.thumbnail} />
                    </div>
                ) : (
                    <div className="asset-management-selector-tile__placeholder">
                        <Illustration size="small" noSquarcle illustration={assetsPlaceholder || 'fallback'} />
                        {/* Clicking the SVG sometimes doesn't always trigger the click event, so we add a div to catch the click event */}
                        <div className="asset-management-selector-tile__clickcatch" />
                    </div>
                )}
                <div className="asset-management-selector-tile__overlay">
                    <Button variant="contained" color="primary" className="asset-management-selector-tile__button">
                        {Translation.get('actions.select', 'common')}
                    </Button>
                </div>
            </div>
            <div className="asset-management-selector-tile__info">
                <div className="asset-management-selector-tile__title">{item.title}</div>
                <div className="asset-management-selector-tile__subtitle">{item.subtitle}</div>
            </div>
        </div>
    );
};

export default AsetManagementSelectorTile;
