import React, { cloneElement } from 'react';
import classNames from 'classnames';
import { Collection } from 'types/collection.type';
import Translation from 'components/data/Translation';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import AssetManagementCollectionTileThumbnails from './thumbnails';
import AssetManagementCollectionTileDisplayOverlay from './display-overlay';

import '../styles/display.scss';

interface Props {
    collection: Collection;
    collectionTileThumbnails?: React.ReactElement;
    hovered: boolean;
    children?: React.ReactElement;
    className?: string;
    onHandleClick: (e: React.MouseEvent<HTMLElement>) => void;
    onSetHovered: (hovered: boolean) => void;
}

const AssetManagementCollectionTileDisplay = ({ collection, collectionTileThumbnails, hovered, children, className, onHandleClick, onSetHovered }: Props) => {
    const { languageNameSpace } = useAssetManagementConfigContext();

    const buildCountText = () => {
        return `${collection.assetCount} ${Translation.get('asset', languageNameSpace, { count: collection.assetCount })} | ${collection.collectionCount} ${Translation.get('collection', 'asset-management', { count: collection.collectionCount })} `;
    };

    return (
        <div
            id={`collection_${collection.id}`}
            className={classNames('asset-management-collection-tile-display', className)}
            onClick={onHandleClick}
            onMouseEnter={() => onSetHovered(true)}
            onMouseLeave={() => onSetHovered(false)}>
            {collectionTileThumbnails ? (
                cloneElement(collectionTileThumbnails, {
                    thumbnails: collection.thumbnails,
                    assetCount: collection.assetCount,
                    hovered,
                    children: (
                        <AssetManagementCollectionTileDisplayOverlay hovered={hovered} collection={collection}>
                            {children}
                        </AssetManagementCollectionTileDisplayOverlay>
                    )
                })
            ) : (
                <AssetManagementCollectionTileThumbnails thumbnails={collection.thumbnails} assetCount={collection.assetCount} hovered={hovered}>
                    <AssetManagementCollectionTileDisplayOverlay hovered={hovered} collection={collection}>
                        {children}
                    </AssetManagementCollectionTileDisplayOverlay>
                </AssetManagementCollectionTileThumbnails>
            )}
            <div className="asset-management-collection-tile-display__title">{collection.title}</div>
            <div className="asset-management-collection-tile-display__count">{buildCountText()}</div>
        </div>
    );
};

export default AssetManagementCollectionTileDisplay;
