import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AssetV2 } from 'types/asset.type';
import Icon from '@mui/material/Icon';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Translation from 'components/data/Translation';
import { useAMNavigation } from 'components/asset-management/hooks';
import { canOrganise } from 'components/asset-management/utilities';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';

interface Props {
    asset: AssetV2<unknown, unknown>;
    onClose: () => void;
}

const AssetManagementAssetMenuItemsOrganise = ({ asset, onClose }: Props) => {
    const { openAssetDetailOverlay } = useAMNavigation();
    const { userHasRight } = useAssetManagementConfigContext();

    const userCanOrganise = canOrganise(userHasRight, asset.status);
    if (!userCanOrganise) return null;

    return (
        <MenuItem
            onClick={(e) => {
                e.stopPropagation();
                openAssetDetailOverlay(asset._id, 'organise');
                onClose();
            }}>
            <ListItemIcon>
                <Icon>bookmarks_outline</Icon>
            </ListItemIcon>
            <ListItemText>{Translation.get('labels.organise', 'common')}</ListItemText>
        </MenuItem>
    );
};

export default AssetManagementAssetMenuItemsOrganise;
