import { get } from 'lodash';
import { AssetV2 } from 'types/asset.type';
import Translation from 'components/data/Translation';
import { ASSET_ROOT_INPUTS } from 'components/asset-management/constants';
import { AssetsNotValidated } from '../../../types/asset-management-edit.type';
import { AssetField } from '../../../types/asset-management-field.type';

// Get the models within the asset that are required, based on the defined inputs.
export const getRequiredAssetModels = (inputs: AssetField[]): string[] => {
    const requiredAssetModels = inputs
        .filter((input) => input.required)
        .map((input) => (ASSET_ROOT_INPUTS.includes(input.key) ? input.key : `labels.${input.key}`));

    // The title should alwas be required.
    requiredAssetModels.push('title');
    return requiredAssetModels;
};

// Validate the assets based on the required models.
export const getAssetsNotValidated = (assets: AssetV2<unknown, unknown>[], requiredAssetModels: string[]): AssetsNotValidated => {
    const assetsNotValidated = {};
    assets.forEach((asset) => {
        if ('_id' in asset && asset._id) {
            const missingKeysInAsset: string[] = [];
            requiredAssetModels.forEach((model) => {
                const value = get(asset, model, undefined);
                if (value === undefined || value === null || ((typeof value === 'string' || Array.isArray(value)) && value.length === 0)) {
                    const keys: string[] = model.split('.');
                    const lastKey: string | undefined = keys.pop();
                    if (lastKey) missingKeysInAsset.push(lastKey);
                }
            });
            if (missingKeysInAsset.length) {
                assetsNotValidated[asset._id] = missingKeysInAsset;
            }
        }
    });
    return assetsNotValidated;
};

// Determine whether there are assets that are not validated for the given input. Returns true if there's an error
export const getErrorForInput = (selectedAssets: AssetV2<unknown, unknown>[], input: AssetField): boolean => {
    const requiredModels = getRequiredAssetModels([input]);
    const assetsNotValidated = getAssetsNotValidated(selectedAssets, requiredModels);
    if (Object.keys(assetsNotValidated).length > 0) return true;
    return false;
};

// Get the default helpertext for an input based on the error, multiple assets and unequal assets.
export const getHelperText = (error: boolean, multipleAssets: boolean, unequal: boolean): string | null => {
    if (error) {
        return Translation.get('validation.fieldRequired', 'common');
    } else if (multipleAssets && unequal) {
        return Translation.get('helpertext.different', 'asset-management');
    }
    return null;
};
