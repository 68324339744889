import React, { createContext, useContext, useState, ReactNode } from 'react';
import ViewState from 'components/data/ViewState';
import { AssetManagementConfigDisplayType } from '../types/asset-management-config.type';
import { useAssetManagementConfigContext } from './asset-management-config.context';

export interface AssetManagementDisplayStateType {
    displayType: AssetManagementConfigDisplayType;
    filterbarOpen: boolean;
    detailbarOpen: boolean;
    collectionsOpen: string[];
}

type AssetManagementDisplayStateContextType = AssetManagementDisplayStateType & {
    setDisplayType: (type: AssetManagementConfigDisplayType) => void;
    setFilterbarOpen: (open: boolean) => void;
    setDetailbarOpen: (open: boolean) => void;
    setCollectionsOpen: (collections: string[]) => void;
};

interface Props {
    children: ReactNode;
    displayState: AssetManagementDisplayStateType;
}

const AssetManagementDisplayStateContext = createContext<AssetManagementDisplayStateContextType>({
    displayType: 'grid',
    filterbarOpen: true,
    detailbarOpen: false,
    collectionsOpen: [],
    setDisplayType: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    setFilterbarOpen: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    setDetailbarOpen: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    setCollectionsOpen: () => {} // eslint-disable-line @typescript-eslint/no-empty-function
});
AssetManagementDisplayStateContext.displayName = 'AssetManagementDisplayStateContext';

const useAMDataDisplayStateContext = (): AssetManagementDisplayStateContextType => {
    return useContext(AssetManagementDisplayStateContext);
};

const AssetManagementDisplayStateProvider = ({ children, displayState }: Props) => {
    const { type } = useAssetManagementConfigContext();

    const [displayType, _setDisplayType] = useState<AssetManagementConfigDisplayType>(displayState.displayType);
    const [filterbarOpen, _setFilterbarOpen] = useState<boolean>(displayState.filterbarOpen);
    const [detailbarOpen, _setDetailbarOpen] = useState<boolean>(displayState.detailbarOpen);
    const [collectionsOpen, _setCollectionsOpen] = useState<string[]>(displayState.collectionsOpen);

    const setDisplayType = (displayType: AssetManagementConfigDisplayType) => {
        _setDisplayType(displayType);
        ViewState.set(type, 'displayType', displayType);
    };

    const setDetailbarOpen = (value: boolean) => {
        _setDetailbarOpen(value);
        ViewState.set(type, 'detailbarOpen', value, 'session');
    };

    const setFilterbarOpen = (value: boolean) => {
        _setFilterbarOpen(value);
        ViewState.set(type, 'filterbarOpen', value, 'session');
    };

    const setCollectionsOpen = (collections: string[]) => {
        _setCollectionsOpen(collections);
        ViewState.set(type, 'collectionsOpen', collections, 'session');
    };

    const contextValue = {
        displayType,
        filterbarOpen,
        detailbarOpen,
        collectionsOpen,
        setDisplayType,
        setFilterbarOpen,
        setDetailbarOpen,
        setCollectionsOpen
    };

    return <AssetManagementDisplayStateContext.Provider value={contextValue}>{children}</AssetManagementDisplayStateContext.Provider>;
};

export default AssetManagementDisplayStateProvider;
export { useAMDataDisplayStateContext, AssetManagementDisplayStateContext, AssetManagementDisplayStateProvider };
