import React from 'react';
import Illustration from 'components/ui-components-cape/Illustration';

import '../styles/placeholder.scss';

const AssetManagementCollectionTilePlaceholder = () => {
    return (
        <div className="asset-management-collection-tile-thumbnails-placeholder">
            <Illustration illustration="collection" />
        </div>
    );
};

export default AssetManagementCollectionTilePlaceholder;
