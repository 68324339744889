import React from 'react';
import { cloneDeep } from 'lodash';
import Icon from 'components/ui-components-v2/Icon';

class ContentSpaceGenericFilterHelpers {
    /**
     * Change a filter setting
     * @param {string} key
     * @param {array} value
     */
    static changeFilter = (key, value, filters, onClearFilterSelection, onToggleFilterSelection) => {
        const thisFilter = filters.find((filter) => filter.name === key);
        if (value === undefined || value.length === 0) {
            onClearFilterSelection(key, thisFilter.filterType);
        } else if (!!thisFilter && thisFilter.type === 'single' && thisFilter.name === 'year') {
            onToggleFilterSelection(thisFilter, parseInt(value));
        } else {
            onToggleFilterSelection(thisFilter, value);
        }
    };

    /**
     * Build the filter object needed by GenericFilter nased on the filters provided bij ContentSpace
     * @param {array} orgFilters
     * @returns
     */
    static getFilters = (orgFilters, locationView) => {
        const filters = {};
        orgFilters.forEach((f) => {
            if (Array.isArray(f.selection) && f.selection.length > 0) {
                if (f.key === 'year') {
                    filters.year = `${f.selection[0]}`;
                } else {
                    filters[f.name] = f.selection.map((s) => `${s}`);
                }
            }
        });
        if (locationView === 'review' || locationView === 'binned') {
            filters.adminSwitch = locationView;
        }
        return filters;
    };

    /**
     * Build the filterSetup array needed by GenericFilter
     * @param {*} filters
     * @param {string} locationView
     * @param {string} filterType
     * @returns
     */
    static getFilterSetup = (filters, locationView, filterType, reviewCount, binnedCount, acceptedTypes, assetFilterOrder = []) => {
        const filterTypeMapping = {
            multiple: 'selectMultiple',
            multipleSubs: 'selectMultipleSubs',
            single: 'select'
        };

        let filterSetup = cloneDeep(filters)
            .filter((f) => {
                // Get the right set of filters.
                if (f.filterType === filterType || ((locationView === 'review' || locationView === 'binned') && f.filterType === 'assets')) {
                    return true;
                } else {
                    return false;
                }
            })
            .filter((f) => f.name !== 'resolutions') // leave out the resolutions filter.
            .filter((f) => {
                // Remove filters with only an 'all' option
                if (
                    (f.name === 'markets' || f.name === 'departments' || f.name === 'brands') &&
                    f.options &&
                    f.options.length === 1 &&
                    f.options[0].key === 'all'
                ) {
                    return false;
                } else {
                    return true;
                }
            })
            .filter((f) => f.options && f.options.length > 0) // Only use filters with options.
            .filter((f) => {
                if (f.options && f.options.length === 1 && !f.options[0].key) {
                    return false;
                } else {
                    return true;
                }
            }) // Don't use filters with only one invalid option.
            .map((filter) => {
                // Sort the filter options by count and by name if the count is the same
                if (filter.options && filter.options.length > 0) {
                    // Get rid of filter options without a valid key
                    filter.options = filter.options.filter((f) => !!f.key);

                    filter.options = filter.options.sort((a, b) => b.count - a.count);

                    filter.options = filter.options.sort((a, b) => {
                        if (a && b && a.key && b.key && a.count && b.count) {
                            if (a.count === b.count) {
                                const stringA = a.key.toString();
                                const stringB = b.key.toString();
                                return stringA.localeCompare(stringB);
                            } else {
                                return 0;
                            }
                        } else {
                            return 0;
                        }
                    });
                }
                return filter;
            });

        // Remove the categories filter from the interface if acceptedTypes are specified.
        if (!!acceptedTypes && acceptedTypes.length > 0) {
            filterSetup = filterSetup.filter((f) => f.name !== 'categories');
        }

        // Apply a fixed order for the standard filters. Custom filters follow after that.
        const filterSort = (a, b) => {
            const indexA = assetFilterOrder.indexOf(a.name);
            const indexB = assetFilterOrder.indexOf(b.name);
            if (indexA > -1 && indexB > -1) {
                return indexA - indexB;
            } else return indexB - indexA;
        };
        filterSetup.sort(filterSort);

        // Convert the filter objects for the GenericFilter.
        filterSetup.forEach((f) => {
            if (f.options[0] && typeof f.options[0].name === 'number') {
                // Mark filters that should return numeric option keys.
                f.numericOptionKeys = true;
            }
            f.type = filterTypeMapping[f.type];
            delete f.selection;
        });

        if (locationView === 'review' || locationView === 'binned') {
            filterSetup.unshift({
                type: 'navigation',
                name: 'adminSwitch',
                options: [
                    {
                        key: 'review',
                        label: 'To review',
                        path: '/asset-library/admin/review',
                        icon: <Icon>thumbs_up_down</Icon>,
                        count: reviewCount
                    },
                    {
                        key: 'binned',
                        label: 'Binned assets',
                        path: '/asset-library/admin/binned',
                        icon: <Icon>delete</Icon>,
                        count: binnedCount
                    }
                ]
            });
        }
        return filterSetup;
    };
}

export default ContentSpaceGenericFilterHelpers;
