import React from 'react';
import classNames from 'classnames';

import './styles/actions-wrapper.scss';

interface Props {
    children: React.ReactNode;
    className?: string;
}

const AssetManagementActionsWrapper = ({ children, className }: Props) => {
    return <div className={classNames('asset-management-actions-wrapper', className)}>{children}</div>;
};

export default AssetManagementActionsWrapper;
