import React from 'react';
import classNames from 'classnames';
import { Collection } from 'types/collection.type';
import AssetManagementCollectionTilePlaceholder from './placeholder';

import '../styles/thumbnails.scss';

interface Props {
    thumbnails: Collection['thumbnails'];
    assetCount?: Collection['assetCount'];
    hovered?: boolean;
    className?: string;
    children?: React.ReactElement;
}

const MAX_THUMBNAILS = 4;

const AssetManagementCollectionTileThumbnails = ({ thumbnails, assetCount, hovered, className, children }: Props) => {
    const thumbCount = thumbnails.length;
    return (
        <div className={classNames('asset-management-collection-tile-thumbnails', `asset-management-collection-tile-thumbnails--${thumbCount}`, className)}>
            {!thumbCount && <AssetManagementCollectionTilePlaceholder />}
            {thumbCount > 0 && <div className="asset-management-collection-tile-thumbnails__first" style={{ backgroundImage: `url(${thumbnails[0]?.url})` }} />}
            {thumbCount > 1 && (
                <div className="asset-management-collection-tile-thumbnails__second" style={{ backgroundImage: `url(${thumbnails[1]?.url})` }} />
            )}
            {thumbCount > 2 && <div className="asset-management-collection-tile-thumbnails__third" style={{ backgroundImage: `url(${thumbnails[2]?.url})` }} />}
            {thumbCount > 3 && (
                <div className="asset-management-collection-tile-thumbnails__fourth" style={{ backgroundImage: `url(${thumbnails[3]?.url})` }}>
                    {hovered && assetCount && assetCount > MAX_THUMBNAILS && (
                        <div className="asset-management-collection-tile-thumbnails__overlay">+{assetCount - MAX_THUMBNAILS}</div>
                    )}
                </div>
            )}
            {children && children}
        </div>
    );
};

export default AssetManagementCollectionTileThumbnails;
