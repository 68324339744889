import React from 'react';
import Button from '@mui/material/Button';

import './styles/compact-button.scss';

interface Props {
    children: React.ReactNode;
    color: 'primary' | 'secondary';
    variant?: 'outlined' | 'contained';
    disabled?: boolean;
    onClick: (e?: React.MouseEvent<HTMLButtonElement>) => void;
}

const AssetManagementCompactButton = ({ children, color, variant = 'outlined', disabled, onClick, ...props }: Props) => {
    return (
        <Button
            data-cy="assetManagement-createCollection-button"
            onClick={onClick}
            className="asset-management-compact-button"
            variant={variant}
            color={color}
            disabled={disabled}
            classes={{
                outlinedSecondary: 'asset-management-compact-button--secondary',
                containedSecondary: 'asset-management-compact-button--contained-secondary'
            }}
            {...props}>
            {children}
        </Button>
    );
};

export default AssetManagementCompactButton;
