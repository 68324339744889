import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';
import { AssetToCreate } from 'types/asset.type';
import { TemplateType } from 'types/templates.type';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Translation from 'components/data/Translation';
import { canOrganise } from 'components/asset-management/utilities';
import { getAssetsNotValidated } from 'components/asset-management/components/edit-asset/utilities';
import { useAMFetch, useAMNavigation } from 'components/asset-management/hooks';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import { TemplateAsset, TemplateCommonData } from 'components/template-management/types/template-management.type';
import { CreativeAsset, CustomCreativeData, TDCreativeData } from 'components/creative-management-v2/types/creative-management.type';
import { CreativeSubType } from 'components/creative-management-v2/types/creative-sub-type.type';
import { getTemplateIdentifier } from 'components/template-management/utilities';

interface Props {
    asset: CreativeAsset | TemplateAsset;
    onClose: () => void;
    onSetWaiting: (waiting: boolean) => void;
}

const AssetManagementAssetMenuItemsCopy = ({ asset, onClose, onSetWaiting }: Props) => {
    const { openAssetDetailOverlay } = useAMNavigation();
    const { fetchAssets } = useAMFetch();
    const { languageNameSpace, requiredAssetModels, userHasRight } = useAssetManagementConfigContext();
    const canOrg = canOrganise(userHasRight, asset.status);

    const copyAsset = async () => {
        onSetWaiting(true);
        const title = `${asset.title} - copy`;
        const data: unknown = { ...asset.data, title };
        if (asset.type === 'template') {
            (data as TemplateCommonData).identifier = getTemplateIdentifier();
        } else if (asset.type === 'creative') {
            (data as TDCreativeData | CustomCreativeData).title = title;
        }
        const duplicateAsset: AssetToCreate<unknown, unknown> & { subType: TemplateType | CreativeSubType } = {
            title,
            data,
            type: asset.type,
            subType: asset.subType,
            public: asset.public,
            labels: asset.labels,
            collections: asset.collections,
            status: 'draft'
        };
        const response = await AssetManagementService.createAsset(duplicateAsset);
        if (response) {
            fetchAssets(true, 'draft');
            onSetWaiting(false);
            const notValidated = getAssetsNotValidated([response.data], requiredAssetModels);
            // We want to start with the 'organise' tab if the asset is not validated, otherwise go to 'edit'.
            const firstView = Object.keys(notValidated).length ? 'organise' : 'edit';
            openAssetDetailOverlay(response.data._id, firstView);
        }
    };

    if (!canOrg) return null;

    return (
        <MenuItem
            onClick={(e) => {
                e.stopPropagation();
                copyAsset();
                onClose();
            }}>
            <ListItemIcon>
                <Icon>content_copy</Icon>
            </ListItemIcon>
            <ListItemText>{Translation.get('actions.duplicate', languageNameSpace)}</ListItemText>
        </MenuItem>
    );
};

export default AssetManagementAssetMenuItemsCopy;
